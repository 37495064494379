var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"classWrapper"},[_c('top-search'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"breadCrump"},[_c('span',[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" Startseite ")])],1),_vm._v(" | "),_c('span',[_c('router-link',{attrs:{"to":{ name: 'cluster', params: {cluster: _vm.$route.params.cluster } }}},[_vm._v(" "+_vm._s(_vm.$route.params.cluster)+" ")])],1),_vm._v(" | "),_c('span',[_vm._v(" "+_vm._s(_vm.info.strSubject)+" ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadComplete == false),expression:"loadComplete == false"}],staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"loadingBox"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 text-center"},[_c('br'),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$route.params.cluster)+" werden geladen...")])])]),_vm._m(0)])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadComplete == true),expression:"loadComplete == true"}],staticClass:"row"},[_c('div',{staticClass:"col-md-12"},_vm._l((_vm.letters),function(letter,index){return _c('div',{key:letter.id,staticClass:"letterWraper"},[(index%5 == 0)?_c('div',{staticClass:"adsense"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.adsense)?_c('Adsense',{attrs:{"data-ad-format":"horizontal","data-ad-client":"ca-pub-2696232756207990","data-ad-slot":"8851516560"}}):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('h4',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(letter.letter.item_letter))])]),_c('div',{staticClass:"col-sm-4 text-right"},[_c('span',[(letter.letter.item_count > 3)?_c('router-link',{attrs:{"to":{ name: 'letter', params: {
                                            cluster: letter.letter.formated_data,
                                            class: letter.letter.formated_subject,
                                            letter: letter.letter.item_letter
                                        }}}},[_vm._v(" "+_vm._s(letter.letter.item_count - 3)+" weitere Ansehen... ")]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_vm._l((letter.data),function(item){return _c('div',{key:item.id_item,staticClass:"col-sm-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm._f("str_limit")(item.item_title,20)))]),_c('p',{staticClass:"card-text",staticStyle:{"min-height":"130px"}},[_vm._v(_vm._s(_vm._f("str_limit")(item.item_plaintext,150)))]),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"btn btn-primary"},[_c('router-link',{attrs:{"to":{ name: 'preview', params: {cluster: item.cluster_formated,class: item.class_1_formated,letter: item.item_letter,item: item.url_id } }}},[_vm._v(" Arbeit ansehen ")])],1)])])])])}),(letter.letter.item_count < 3)?_c('div',{staticClass:"col-sm"},[_vm._m(1,true)]):_vm._e()],2)])}),0)])]),_c('share-collect')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"loadingBar",staticStyle:{"position":"absolute","left":"50%","top":"15px","transform":"translateX(-50%)"}},[_c('div',{staticClass:"lds-default"},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body text-center",staticStyle:{"text-transform":"uppercase","background":"#f1f1f1","display":"flex","align-items":"center","justify-content":"center","min-height":"260px"}},[_c('b',[_vm._v("Keine weiteren Arbeiten vorhanden")])])])
}]

export { render, staticRenderFns }