<template>
    <div class="classWrapper">
        <top-search></top-search>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="breadCrump">
                        <span>
                            <router-link to="/">
                                Startseite
                            </router-link>
                        </span>
                        |
                        <span>
                            <router-link :to="{ name: 'cluster', params: {cluster: $route.params.cluster } }">
                                {{$route.params.cluster}}
                            </router-link>
                        </span>
                        |
                        <span>
                            {{info.strSubject}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row" v-show="loadComplete == false">
                <div class="col-md-12">
                    <div class="loadingBox">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <br><br>
                                <b>{{$route.params.cluster}} werden geladen...</b>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="loadingBar" style="position: absolute;left:50%;top:15px;transform: translateX(-50%);">
                                    <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="loadComplete == true">
                <div class="col-md-12">
                    <div class="letterWraper" v-for="(letter,index) in letters" :key="letter.id" >
                        <div class="adsense" v-if="index%5 == 0">
                            <div class="row">
                                <div class="col-12">
                                    <Adsense v-if="adsense"
                                             data-ad-format="horizontal"
                                             data-ad-client="ca-pub-2696232756207990"
                                             data-ad-slot="8851516560">
                                    </Adsense>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <h4 style="text-transform: uppercase;">{{letter.letter.item_letter}}</h4>
                            </div>
                            <div class="col-sm-4 text-right">
                                <span>
                                    <router-link
                                            v-if="letter.letter.item_count > 3"
                                            :to="{ name: 'letter', params: {
                                                cluster: letter.letter.formated_data,
                                                class: letter.letter.formated_subject,
                                                letter: letter.letter.item_letter
                                            }}">
                                        {{letter.letter.item_count - 3}} weitere Ansehen...
                                    </router-link>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4" v-for="item in letter.data" :key="item.id_item">
                                <div class="card" >
                                    <div class="card-body" >
                                        <h5 class="card-title">{{item.item_title | str_limit(20)}}</h5>
                                        <p class="card-text" style="min-height: 130px">{{item.item_plaintext | str_limit(150)}}</p>
                                        <div class="text-center">
                                            <span class="btn btn-primary ">
                                                <router-link :to="{ name: 'preview', params: {cluster: item.cluster_formated,class: item.class_1_formated,letter: item.item_letter,item: item.url_id } }">
                                                    Arbeit ansehen
                                                </router-link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm"  v-if="letter.letter.item_count < 3">
                                <div class="card" >
                                    <div class="card-body text-center" style="text-transform: uppercase;background:#f1f1f1;display: flex;align-items: center;justify-content: center;min-height: 260px">
                                        <b>Keine weiteren Arbeiten vorhanden</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <share-collect></share-collect>
    </div>
</template>

<script>
    import TopSearch from "../components/TopSearch";
    import ShareCollect from "../components/ShareCollect";
    import axios from "axios";
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InArticleAdsense)
    Vue.use(Ads.InFeedAdsense)
    export default {
        name: 'Class',
        components: {ShareCollect, TopSearch},
        data: function () {
            return {
                loadComplete : false,
                info: {},
                adsense : false,
                letters: []
            }
        },
        methods:{
            getItems: function () {
                axios.get('https://api.pausenhof.de/class/letterlist/'+this.$route.params.class,
                    {headers:{'authorization': '3241451345'}}).then(response => {
                    this.letters = response.data.content;
                    this.info = response.data.info;
                    this.loadComplete = true;
                });
            }
        },
        created () {
            this.getItems();
            let cookieConsent=  Vue.$cookies.get("rephCookieCons");
            if(cookieConsent == '111') {
                this.adsense = true;
            } else if(cookieConsent == '101') {
                this.adsense = true;
            } else if(cookieConsent == '110') {
                this.adsense = false;
            }
        },
    }
</script>

<style scoped>
    .searchWraper h1{
        font-family: 'Londrina Shadow';
        color:white;
        font-size: 3.5rem;
    }
    .letterWraper .btn-primary{
        background:#f76d6d;
        border-color:#f76d6d;
        color:#fff !important;
    }
    .letterWraper .btn-primary a{
        color:#fff !important;
    }
    .letterWraper .btn-primary a:hover{
        text-decoration: none !important;
    }
    .letterWraper{
        padding:25px 0;
    }

</style>

